<template>
  <app-page-layout
    v-bind="content"
    class="page-suistainable"
  >
    <div
      v-for="(item, index) in content.data"
      :key="index"
      class="page-suistainable__block"
    >
      <div class="page-suistainable__block-container">
        <div class="page-suistainable__block-image">
          <img :src="item.image" alt="">
        </div>
        <div class="page-suistainable__block-data">
          <div class="page-suistainable__block-header">
            <div class="page-suistainable__block-title">
              {{ item.title }}

              <img
                v-for="(icon, i) in item.icons"
                :key="i"
                class="page-suistainable__block-icon"
                :src="icon"
                alt=""
              >
            </div>
          </div>

          <div class="page-suistainable__block-text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </app-page-layout>
</template>

<script>
import AppPageLayout from '@/components/app-page-layout';

export default {
  components: {
    AppPageLayout,
  },
  computed: {
    content() {
      return this.$t('page.suistainable');
    },
  },
};
</script>

<style lang="scss">
.page-suistainable {
  &__block {
    $block: &;

    margin-top: 90px;
    margin-bottom: 90px;

    padding-top: 32px;
    padding-bottom: 32px;

    background: #F8F7F7;

    &-container {
      @include container;

      display: flex;

      #{$block}:nth-child(even) & {
        flex-direction: row-reverse;
      }
    }

    &-image {
      border-radius: 12px;
      opacity: 0.9;

      flex: 0 0 53%;
      max-width: 53%;

      img {
        width: 100%;
      }
    }

    &-data {
      padding: 0 36px;

      #{$block}:nth-child(even) & {
        padding-right: 7%;
      }
    }

    &-header {
      margin-bottom: 16px;

      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }

    &-title {
      font-weight: 700;
      font-size: 28px;
      line-height: 138.19%;
      color: #CA1025;
    }

    &-icon {
      max-width: 24px;
      max-height: 24px;
      margin-left: 8px;
    }

    &-text {
      font-weight: 500;
      font-size: 20px;
      line-height: 138.19%;
      color: #343434;

      white-space: pre-line;
    }
  }

  @include media-bp(tab) {
    &__block {
      $block: &;

      &-image {
        flex: 0 0 45%;
        max-width: 45%;
      }

      &-data {
        padding: 0 24px;

        #{$block}:nth-child(even) & {
          padding-right: 24px;
        }
      }

      &-header {
        margin-bottom: 12px;
      }

      &-title {
        font-size: 20px;
      }

      &-icon {
        max-width: 18px;
        max-height: 18px;
      }

      &-text {
        font-size: 16px;
      }
    }
  }

  @include media-bp(mob) {
    &__block {
      $block: &;

      margin-top: 20px;
      margin-bottom: 56px;

      padding: 0;

      background: none;

      &-container {
        &,
        #{$block}:nth-child(even) & {
          flex-direction: column-reverse;
        }
      }

      &-data {
        &,
        #{$block}:nth-child(even) & {
          padding: 0;
        }
      }

      &-header {
        margin-bottom: 8px;
      }

      &-title {
        font-size: 24px;
        line-height: 138.19%;
      }

      &-icon {
        max-width: 12px;
        max-height: 12px;
      }

      &-text {
        font-size: 16px;
        line-height: 138.19%;
      }

      &-image {
        flex: 0 0 100%;
        max-width: 100%;

        margin-top: 16px;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
